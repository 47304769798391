<!--
 * @Descripttion: 水刀拼花
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-12 10:01:43
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-12 16:40:13
-->
<template>
    <div class="jetParquet">
        <div class="add-wrap">
            <el-button @click="clickShowScene" class="btns" type="primary">添加水刀拼花</el-button>
        </div>
        <div class="table-wrap" v-loading="loading">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column align="center" prop="pavesId" label="ID" width="60"></el-table-column>
                <el-table-column align="center" prop="pavesImageId" label="图片" width="90">
                    <template slot-scope="scope">
                        <img :src="scope.row.pavesImageId" height="30" />
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="pavesColorFileId" label="颜色图" width="90">
                    <template slot-scope="scope">
                        <img :src="scope.row.pavesColorFileId" height="30" />
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="pavesVersion" label="版本" width="60"></el-table-column>
                <el-table-column align="center" prop="pavesColorCount" label="砖数量" width="80"></el-table-column>
                <el-table-column align="center" prop="pavesName" label="名称" ></el-table-column>
                <el-table-column align="center" prop="pavesType" label="功能分类" ></el-table-column>
                <el-table-column align="center" prop="numberTypes" label="片数类型" >
                    <template slot-scope="scope">
                        <div v-if="scope.row.numberTypes == 1">单片</div>
                        <div v-if="scope.row.numberTypes == 2">双片</div>
                        <div v-if="scope.row.numberTypes == 3">多片</div>
                        <div v-if="scope.row.numberTypes == 4">拼花</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="create_time" label="创建时间" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" label="操作" width="90">
                    <template slot-scope="scope">
                        <div class="operation-wrap">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <i @click="clickDelete(scope.row)" class="iconfont el-icon-delete"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
            :visible.sync="isShowScene" 
            @close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title" v-if="editId<0">添加切割铺贴</div>
            <div class="title" v-else>编辑切割铺贴</div>
            <div class="content">
                <el-form :model="pavesForm" :rules="pavesRules" ref="pavesForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="名称" prop="pavesName">
                        <el-input v-model="pavesForm.pavesName" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="砖数量" prop="pavesColorCount">
                        <el-input v-model="pavesForm.pavesColorCount" placeholder="请输入砖数量"></el-input>
                    </el-form-item>
                    <el-form-item label="版本号" prop="pavesVersion">
                        <el-input v-model="pavesForm.pavesVersion" placeholder="请输入版本号"></el-input>
                    </el-form-item>
                    <el-form-item label="片数类型" prop="numberTypes" required>
                        <el-radio-group v-model="pavesForm.numberTypes">
                            <el-radio :label="1">单片</el-radio>
                            <el-radio :label="2">双片</el-radio>
                            <el-radio :label="3">多片</el-radio>
                            <el-radio :label="4">拼花</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="展示小图" prop="pavesImageId">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="8" :limit="1" :isShopTip="false"
                                :btnDisplay="image.length<1" listType="picture-card"
                                v-model="image">
                            </aliyun-upload>
                            <div v-if="image.length>0" class="fileList-box">
                                <div @click="delImageList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="image" :src="image[0]"/>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="颜色图" prop="pavesImageId">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="8" :limit="1" :isShopTip="false"
                                :btnDisplay="colorImage.length<1" listType="picture-card"
                                v-model="colorImage">
                            </aliyun-upload>
                            <div v-if="colorImage.length>0" class="fileList-box">
                                <div @click="delColorImageList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="colorImage" :src="colorImage[0]"/>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div class="btn-wrap">
                <el-button v-if="editId<0" :loading="btnLoading" @click="creationPaves" type="primary">立刻创建</el-button>
                <el-button v-else :loading="btnLoading" @click="editPaves" type="primary">编辑</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    data () {
        return {
            loading: false,
            tableData: [],
            total: 0,
            currentPage: 1,
            isShowScene: false,
            pavesForm: {
                pavesType: 'PINHUA',
                pavesName: '',
                pavesColorCount: '',
                pavesVersion: '',
                numberTypes: 1,
                pavesImageId: '',
                pavesColorFileId: ''
            },
            pavesRules: {
                pavesName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                pavesColorCount: [
                    { required: true, message: '请输入砖数量', trigger: 'blur' },
                ],
                pavesVersion: [
                    { required: true, message: '请输入版本号', trigger: 'blur' },
                ],
                pavesImageId: [
                    { required: true, message: '请上传展示小图', trigger: 'blur' },
                ],
                pavesColorFileId: [
                    { required: true, message: '请上传颜色图', trigger: 'blur' },
                ],
            },
            image: [],
            colorImage: [],
            btnLoading: false,
            editId: -1
        }
    },
    watch: {
        image(newData, oldData) {
            this.pavesForm.pavesImageId = newData[0]
        },
        colorImage(newData,oldData){
            this.pavesForm.pavesColorFileId = newData[0]
        }
    },
    mounted () {
        this.getTilingIndex();
    },
    methods: {
        // 创建
        creationPaves(){
            this.$refs.pavesForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    common.connect('/customerservicev1/scene/storeTiling',this.pavesForm,(res)=>{
                        this.getTilingIndex();
                        this.isShowScene = false;
                        this.btnLoading = false;
                        this.image = [];
                        this.pavesForm.pavesName = '';
                    })
                    setTimeout(()=>{
                        this.btnLoading = false;
                    },3000)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑
        editPaves(){
            this.$refs.pavesForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    this.pavesForm.pavesId = this.editId;
                    common.connect('/customerservicev1/scene/updateTiling',this.pavesForm,(res)=>{
                        this.getTilingIndex();
                        this.isShowScene = false;
                        this.btnLoading = false;
                        this.image = [];
                        this.colorImage = [];
                        this.editId = -1;
                        this.pavesForm.pavesName = '';
                    })
                    setTimeout(()=>{
                        this.btnLoading = false;
                    },3000)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑页
        clickEdit(row){
            this.editId = row.pavesId;
            common.connect('/customerservicev1/scene/editTiling',{pavesId: row.pavesId},(res)=>{
                this.isShowScene = true;
                this.image[0] = res.data.pavesImageId;
                this.colorImage[0] = res.data.pavesColorFileId;
                this.pavesForm = res.data;
            })
        },
        // 删除
        clickDelete(row){
            this.$confirm('此操作将永久删除该风格, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/customerservicev1/scene/deleteTiling',{pavesId: row.pavesId},(res)=>{
                    this.getTilingIndex();
                    this.$message.success('删除成功！')
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 获取风格列表数据
        getTilingIndex(){
            let params = {
                page: this.currentPage,
                pavesType: 'PINHUA'
            }
            this.loading = true;
            common.connect('/customerservicev1/scene/tilingIndex',params,(res)=>{
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            })
        },
        delColorImageList(){
            this.colorImage.splice(0, 1)
        },
        delImageList(){
            this.image.splice(0, 1)
        },
        clickShowScene(){
            this.isShowScene = true;
        },
        // 关闭弹窗
        handleClose(){
            let pavesForm = {
                pavesType: 'PINHUA',
                pavesName: '',
                pavesColorCount: '',
                pavesVersion: '',
                numberTypes: 1,
                pavesImageId: ''
            }
            this.isShowScene = false;
            this.editId = -1;
            this.image = [];
            this.colorImage = [];
            this.pavesForm = pavesForm;
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getTilingIndex();
        }
    },
}
</script>

<style lang='scss'>
.jetParquet{
    .add-wrap{
        display: flex;
        justify-content: flex-end;
    }
    .table-wrap{
        margin-top: 20px;
        .image{
            width: 40px;
            cursor: pointer;
        }
        .operation-wrap{
            display: flex;
            justify-content: center;
            .iconfont{
                font-size: 16px;
                padding: 0 10px;
                cursor: pointer;
            }
        }
        .pagination-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;
        }
    }
    .dialog-wrap{
        width: 700px;
        .title{
            font-size: 16px;
            color: #000;
            margin-left: 20px;
        }
        .content{
            margin: 40px 20px 20px 20px;
            .upload-wrap{
                padding-top: 2px;
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            padding: 15px 0;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
