<!--
 * @Descripttion: 铺贴方案
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-12 09:43:31
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-08-11 14:43:12
-->
<template>
    <div class="pavingScheme">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">铺贴方案</div>
        </div>
        <div class="case-wrap">
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="切割铺贴" name="first">
                    <cutPaving ref="cutPaving"></cutPaving>
                </el-tab-pane>
                <el-tab-pane label="布局方案" name="second">
                    <placementScheme ref="placementScheme"></placementScheme>
                </el-tab-pane>
                <el-tab-pane label="水刀拼花" name="thirdly">
                    <jetParquet ref="jetParquet"></jetParquet>
                </el-tab-pane>
                <el-tab-pane label="水刀魔方" name="fourthly">
                    <jetRubik ref="jetRubik"></jetRubik>
                </el-tab-pane>
                <el-tab-pane label="切割布局" name="fifth">
                    <cutScheme ref="cutScheme"></cutScheme>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import cutPaving from './psComponents/cutPaving'
import placementScheme from './psComponents/placementScheme'
import jetParquet from './psComponents/jetParquet'
import jetRubik from './psComponents/jetRubik'
import cutScheme from './psComponents/cutScheme'
export default {
    components:{
        cutPaving,
        placementScheme,
        jetParquet,
        jetRubik,
        cutScheme
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'pavingScheme',
            activeName: 'first',
        }
    },
    mounted () {

    },
    methods: {

    },
}
</script>

<style lang='scss'>
.pavingScheme{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
    }
}
</style>
